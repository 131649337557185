import React from 'react';
import styled from 'styled-components';
import {
  RewardAwardRecordButton,
  RewardPaymentButton,
  RewardFineRecordButton,
} from '../Buttons';
import { IUseStateRewardReturnType } from '@hooks';
import {
  ProfileModel,
  RewardMapper,
  RewardModel,
  RewardStatsDTO,
} from '@structure';
import { useTranslation } from 'react-i18next';

export interface IRewardHeaderProps
  extends Pick<
    IUseStateRewardReturnType,
    | 'handleCreateRewardAwardRecord'
    | 'handleCreateRewardFineRecord'
    | 'handleCreateRewardPayment'
  > {
  profile: ProfileModel | null;
  stats: RewardStatsDTO | null;
  daleyFocus: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 30px;
`;

const StyledRecordContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  width: auto;
  height: auto;
`;

export function RewardHeader({
  handleCreateRewardFineRecord,
  handleCreateRewardAwardRecord,
  handleCreateRewardPayment,
  profile,
  stats,
  daleyFocus,
}: IRewardHeaderProps): React.JSX.Element {
  const { t } = useTranslation();

  const totalSumForPeriod = Number(stats?.totalSumForPeriod || 0);

  return (
    <StyledContainer>
      <RewardPaymentButton
        disabled={false}
        payment={RewardMapper.toRewardPaymentFormDTO({} as RewardModel, {
          cashier: profile?.defaultEmployeeModel,
          employee: stats?.employee,
          stats: stats!,
          comment: `${t('Payment of charges')} ${stats?.employee?.fullName}`,
        })}
        onSuccess={handleCreateRewardPayment as any}
        className="reward-create-event"
        onCancel={daleyFocus}
      />
      <StyledRecordContainer>
        <RewardAwardRecordButton
          reward={RewardMapper.toRewardFormDTO({} as RewardModel)}
          onSuccess={handleCreateRewardAwardRecord}
          onCancel={daleyFocus}
        />

        <RewardFineRecordButton
          reward={RewardMapper.toRewardFormDTO({} as RewardModel)}
          onSuccess={handleCreateRewardFineRecord}
          onCancel={daleyFocus}
        />
      </StyledRecordContainer>
    </StyledContainer>
  );
}

import { useAcl } from '@contex';
import { StoreDetailsView } from '../../Show';
import { useTranslation } from 'react-i18next';
import { StoreRealizationTabPanel } from '../Show';
import { ClientDetailsView } from '../../../Clients';
import styled, { useTheme } from 'styled-components';
import { BsCashCoin } from 'react-icons/bs';
import { Col, MenuProps, Popconfirm, Row } from 'antd';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { toDateByFormat } from '@services/helpers';
import { Routes, RoutesAcl } from '@services/types';
import { productStoreDocumentRoutes } from '../../../Products';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewLayout } from '@components/lib/Layout';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { DocumentControlPanel } from '@components/lib/General';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';

import {
  HiOutlineDocument,
  HiOutlineClipboardDocumentList,
} from 'react-icons/hi2';

import {
  Button,
  HeaderLeft,
  DetailsView,
  CommentField,
  SuspenseEmpty,
  IDetailsViewItemProps,
} from '@components/lib/DataDisplay';

import {
  useStopLoading,
  useStoredProfile,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
  useStateStoreDocumentItemList,
} from '@hooks';

import {
  StoreDocItemsView,
  StoreDocumentBottomView,
  StoreDocumentPageHeader,
  StoreDocExtraServicesView,
} from '../../Show';
import {
  StoreDocumentType,
  StoreDocumentModel,
  StoreDocumentMapper,
  StoreDocumentStatus,
  StorePaymentDocumentFormDTO,
  StorePaymentDocumentPostActionType,
} from '@structure';

enum StoreDocumentPrintType {
  DOCUMENT = 'document',
  CHECK = 'check',
  UNKNOWN = 'unknown',
}

const CloseDocumentSideWindowLazy = React.lazy(
  () => import('../../Show/StorePaymentDocumentSideWindow'),
);

const StoreRealizationSideWindowLazy = React.lazy(
  () => import('../Show/StoreRealizationSideWindow'),
);

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;
const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;
const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

export function StoreRealizationPage() {
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = useStoredProfile();
  const { t } = useTranslation();
  const { documentId, productId } = useParams();
  const [deletedLoading, setDeletedLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState<StoreDocumentPrintType>(
    StoreDocumentPrintType.UNKNOWN,
  );

  const { defaultCompanyUuid } = useStoredCompanies();
  const { store: storeAccess } = useAcl((acl) => acl);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,
    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.OUT,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const isDraft: boolean = useMemo<boolean>(
    (): boolean => document?.doc_status_text === StoreDocumentStatus.DRAFT,
    [document?.doc_status_text],
  );

  const isRelatable: boolean = useMemo<boolean>(
    (): boolean => !!document?.doc_is_relatable,
    [document?.doc_is_relatable],
  );

  const isPaymentable: boolean = useMemo<boolean>(
    (): boolean => !!document?.doc_is_paymentable,
    [document?.doc_is_paymentable],
  );

  const storePayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        document || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel,
          comment: `${t(
            'Realization of the goods according to the invoice',
          )} №${document?.doc_local_number} ${t('From')} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
          withoutDiscount: true,
        },
      ),
    [document, profile?.defaultEmployeeModel, t],
  );

  const {
    handleCancel: storePaymentHandleCancel,
    visible: storePaymentVisible,
    handleSuccess: storePaymentHandleSuccess,
    handleOnInit: storePaymentHandleOnInit,
  } = useModal<StorePaymentDocumentFormDTO>({
    onSuccess: async (value) => {
      await handlePaymentStoreDocument(value, {
        with_document: true,
        with_stats: true,
      });
    },
  });

  const {
    handleCancel: storeEditHandleCancel,
    handleOnInit: storeDocumentEditOnInit,
    handleSuccess: storeDocumentEditSuccess,
    visible: storeDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateStoreDocument,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store sales invoices loading',
  });

  const { handleDeleteStoreDocuments: onDeleteStoreDocuments } =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.OUT,
      loadOnInit: false,
    });

  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storeRealization}`,
    );
  }, [location?.state?.goBack, navigate]);
  const handleDeleteStoreDocuments = useCallback(async () => {
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
  }, [navigateGoBack, onDeleteStoreDocuments, documentId]);
  const onGoToPaymentsButtonClick = useCallback(() => {
    return navigate(`${Routes.payments}/${Routes.stores}`);
  }, [navigate]);
  const navigateToRelatedDocument = useCallback(() => {
    navigate(`${Routes.storeRelated}`);
  }, [navigate]);

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storeRealization}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storeRealization}`,
            breadcrumbName: 'Sales invoices',
          },
          {
            path: `/${Routes.stores}/${Routes.storeRealization}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show sales invoice',
          },
        ],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Client',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];
  const documentOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'product',
        disabled: !isPaymentable,
        label: <StyledTitle>{t('List of payments')}</StyledTitle>,
        icon: <BsCashCoin size={15} />,
        onClick: onGoToPaymentsButtonClick,
      },
      { type: 'divider' },
      {
        key: 'invoice',
        type: 'group',
        label: t('Invoice'),
        children: [
          {
            key: 'add-to-invoice',
            disabled: false,
            label: <StyledTitle>{t('Add to invoice...')}</StyledTitle>,
            icon: <LiaFileInvoiceDollarSolid size={15} />,
          },
        ],
      },
      { type: 'divider' },
      {
        key: 'linked-documents',
        label: <StyledTitle>{t('Related documents')}</StyledTitle>,
        title: t('List of related documents'),
        disabled: !isRelatable,
        icon: <AiOutlineUnorderedList size={15} />,
        onClick: navigateToRelatedDocument,
      },
      { type: 'divider' },
      {
        key: 'delete-product',
        disabled: !isDraft,
        label: !isDraft ? (
          <StyledTitle>
            <StyledDeleteOutlined /> {t('Delete')}
          </StyledTitle>
        ) : (
          <Popconfirm
            title={t('Are you sure you want to delete the document?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onCancel={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            onConfirm={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();

              await handleDeleteStoreDocuments();
            }}>
            <StyledPopconfirmButton
              type="text"
              onClick={async (e: any) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              }}>
              {deletedLoading ? (
                <StyledLoadingOutlined />
              ) : (
                <StyledDeleteOutlined />
              )}
              <StyledTitle $color={theme.colors.error}>
                {t('Delete draft')}
              </StyledTitle>
            </StyledPopconfirmButton>
          </Popconfirm>
        ),
      },
    ],
    [
      t,
      theme,
      isDraft,
      isRelatable,
      isPaymentable,
      deletedLoading,
      navigateToRelatedDocument,
      onGoToPaymentsButtonClick,
      handleDeleteStoreDocuments,
    ],
  );
  // @ts-ignore
  const printOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'Print document',
        icon:
          printLoading === StoreDocumentPrintType.DOCUMENT ? (
            <LoadingOutlined />
          ) : (
            <HiOutlineDocument size={15} color={theme.colors.black} />
          ),
        label: <StyledTitle>{t('Print document')}</StyledTitle>,
        onClick: handlePrintStoreDocument,
      },
      {
        key: 'Print check',
        icon:
          printLoading === StoreDocumentPrintType.CHECK ? (
            <LoadingOutlined />
          ) : (
            <HiOutlineClipboardDocumentList
              size={15}
              color={theme.colors.black}
            />
          ),
        label: <StyledTitle>{t('Print check')}</StyledTitle>,
        onClick: () => handlePrintStoreDocumentCheck(document?.uuid),
      },
    ],
    [
      t,
      handlePrintStoreDocument,
      handlePrintStoreDocumentCheck,
      printLoading,
      theme.colors.black,
      document?.uuid,
    ],
  );

  return (
    <>
      <StoreRealizationTabPanel document={document}>
        {({ tabs }) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Sales invoice')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={storeDocumentEditOnInit}
                isDraft={
                  document?.doc_status_text === StoreDocumentStatus.DRAFT
                }
              />
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader
                  document={document}
                  withoutPayment={true}
                />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                handlePaymentStoreDocument={storePaymentHandleOnInit}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit sales invoice')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StoreRealizationTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <CloseDocumentSideWindowLazy
          title={`${t('Closing posting')}`}
          payment={storePayment!}
          loading={loading}
          onSuccess={storePaymentHandleSuccess}
          onCancel={storePaymentHandleCancel}
          visible={storePaymentVisible}
        />
      </Suspense>
      <Suspense fallback={<SuspenseEmpty />}>
        <StoreRealizationSideWindowLazy
          editMode
          document={StoreDocumentMapper.toStoreDocumentFormDTO(document!, {
            editMode: true,
          })}
          visible={storeDocumentVisible}
          onCancel={storeEditHandleCancel}
          onSuccess={storeDocumentEditSuccess}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}

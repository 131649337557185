import { Routes } from '@services/types';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeModel } from '@structure';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { correctPrice } from '@services/helpers';
import { StyledDescription } from '@components/lib/Styled';

const StyledEmployeeBalanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 5px 0;
  gap: 5px;
`;

const StyledBalanceLink = styled(Link)`
  font-weight: 500;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export interface IEmployeeBalanceProps {
  employee: EmployeeModel | null;
  currency?: string;
  explainBalance?: boolean;
}

export function EmployeeBalance({
  employee,
  currency,
  explainBalance = true,
}: IEmployeeBalanceProps): JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();

  const employeeBalance: number = useMemo<number>(
    () => Number(employee?.balance) || 0,
    [employee?.balance],
  );
  const employeeColor: string = useMemo<string>(() => {
    if (!employeeBalance) {
      return theme.colors.black;
    }

    if (employeeBalance < 0) {
      return theme.colors.error;
    }

    return theme.colors.success;
  }, [
    employeeBalance,
    theme.colors.black,
    theme.colors.error,
    theme.colors.success,
  ]);

  const balance = employee?.balance?.startsWith('-')
    ? employee?.balance.slice(1)
    : employee?.balance;

  return (
    <StyledEmployeeBalanceContainer>
      <StyledDescription fontSize={15} $color={employeeColor}>
        {`${t('Balance')} (${currency}): `}
        {explainBalance ? (
          <StyledBalanceLink
            to={`/${Routes.app}/${Routes.employees}/${employee?.uuid}/${Routes.rewards}`}>
            {correctPrice(balance)}
          </StyledBalanceLink>
        ) : (
          correctPrice(balance)
        )}
      </StyledDescription>
      {employeeBalance !== 0 && (
        <Tooltip
          title={
            employeeBalance === 0
              ? undefined
              : employeeBalance > 0
              ? t('We are guilty')
              : t('We are to blame')
          }>
          <StyledInfoCircleOutlined $color={employeeColor} />
        </Tooltip>
      )}
    </StyledEmployeeBalanceContainer>
  );
}

import { RewardStatsDTO } from '@structure';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IRewardListHeaderViewProps {
  stats: RewardStatsDTO | null;
}

const StyledRewardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 30px;
`;

export function RewardListHeaderView(
  props: IRewardListHeaderViewProps,
): React.JSX.Element {
  const { t } = useTranslation();
  const { stats } = props;

  console.log(' ---> ', stats);

  return (
    <StyledRewardHeaderContainer>
      Hello
      <div>{t('Hello2')}</div>
    </StyledRewardHeaderContainer>
  );
}

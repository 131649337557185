import { List } from 'immutable';
import {
  RewardDTO,
  RewardFormDTO,
  IRewardDTOProps,
  RewardModel,
  RewardListModel,
  RewardStatsDTO,
  RewardPaymentFormDTO,
  IEmployeeDTOProps,
  IRewardAdditionalProps,
} from '../internal';
import { listToArray } from '@services/helpers';
import { WithoutRecursion } from '../type';

export interface IRewardListDTO {
  rewards: RewardDTO[];
  stats: RewardStatsDTO;
  total: number;
}

export class RewardMapper {
  public static toRewardDTO(
    reward: RewardModel | IRewardDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): RewardDTO {
    return new RewardDTO(reward, [
      WithoutRecursion.reward,
      ...withoutRecursion,
    ]);
  }

  public static toRewardStatsDTO(
    total: number,
    employee: IEmployeeDTOProps,
    totalAccrued: number = 0,
    totalPayoutSumForPeriod: number = 0,
  ): RewardStatsDTO {
    return new RewardStatsDTO({
      total,
      employee,
      totalSumForPeriod: totalAccrued,
      totalPayoutSumForPeriod,
    });
  }

  public static toRewardListDTO(
    rewards: List<RewardModel> | IRewardDTOProps[],
    total: number,
    stats: RewardStatsDTO = {} as RewardStatsDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): IRewardListDTO {
    const rewardList = List.isList(rewards)
      ? listToArray<RewardModel>(rewards)
      : rewards;

    return {
      rewards: rewardList?.map((reward) =>
        RewardMapper.toRewardDTO(reward, [
          WithoutRecursion.reward,
          ...withoutRecursion,
        ]),
      ),
      total,
      stats,
    };
  }

  public static toRewardModel(
    rewardDTO: RewardDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): RewardModel {
    return new RewardModel(rewardDTO, [
      WithoutRecursion.reward,
      ...withoutRecursion,
    ]);
  }

  public static toRewardListModel(
    rewardDTOs: RewardDTO[],
    total: number,
    stats: RewardStatsDTO = {} as RewardStatsDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): RewardListModel {
    return new RewardListModel({ rewards: rewardDTOs, total, stats }, [
      WithoutRecursion.reward,
      ...withoutRecursion,
    ]);
  }

  public static toRewardFormDTO(
    rewardModel: RewardModel,
    editMode?: boolean,
  ): RewardFormDTO {
    return new RewardFormDTO(rewardModel, editMode);
  }

  public static toRewardPaymentFormDTO(
    rewardModel: RewardModel,
    additionalProps: IRewardAdditionalProps,
  ): RewardPaymentFormDTO {
    return new RewardPaymentFormDTO(rewardModel, additionalProps);
  }
}

import {
  PAYMENT_METHOD,
  RewardModel,
  EmployeeModel,
  CashBoxModel,
  GroupPaymentDTO,
  RewardStatsDTO,
} from '../internal';
import { Moment } from 'moment';
import { correctPrice, toMoment } from '@services/helpers';

export interface IRewardAdditionalProps {
  editMode?: boolean;
  cashier?: EmployeeModel;
  employee?: EmployeeModel;
  stats?: RewardStatsDTO;
  comment: string;
}

export class RewardPaymentFormDTO {
  public uuid: string;
  public payment_price: string;
  public payment_comment: string;
  public payment_date: string | Moment;
  public payment_method: PAYMENT_METHOD;
  public payment_cashier_uuid: string | EmployeeModel;
  public payment_cashbox_uuid: string | CashBoxModel;
  public payment_group_uuid: string | GroupPaymentDTO;
  public employee?: EmployeeModel;
  public sum_total?: string;

  constructor(
    props: RewardModel,
    {
      editMode,
      cashier,
      employee,
      stats,
      comment = '',
    }: IRewardAdditionalProps,
  ) {
    const sumAccruedForPeriod = stats?.totalSumForPeriod || 0;
    const sumPaidForPeriod = stats?.totalPaid || 0;

    this.uuid = props?.uuid || '';
    this.payment_comment = comment;
    this.payment_method = PAYMENT_METHOD.Cash;
    this.payment_date = toMoment(new Date());
    this.payment_price = correctPrice(
      sumAccruedForPeriod > sumPaidForPeriod
        ? sumAccruedForPeriod - sumPaidForPeriod
        : 0,
    );
    this.payment_group_uuid = '';
    this.payment_cashier_uuid = cashier || '';
    this.payment_cashbox_uuid = '';
    this.employee = employee;
    this.sum_total = correctPrice(stats?.totalSumForPeriod || 0);
  }
}

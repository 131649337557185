import { MenuProps } from 'antd';
import { ExpenseList } from '../List';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { useAcl, useCalendar } from '@contex';
import OrderPageTabPanel from '../Show/OrderPageTabPanel';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useModal } from '@components/lib/libV2/hooks';
import { OverviewLayout } from '@components/lib/Layout';
import { DocumentControlPanel } from '@components/lib/General';
import { correctPrice, textToUpperCase } from '@services/helpers';
import { OrderBottomView, OrderDetailsHeader, OrderHeader } from '../Show';
import { HeaderLeft, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';

import {
  useStateSchedule,
  useStoredFileList,
  useStoredCompanies,
  useStateExpenseList,
  useStoredStatusList,
  useStateSchedulePaymentList,
} from '@hooks';

import {
  ScheduleStatusesText,
  PaymentScheduleFormDTO,
  ScheduleCalendarMapper,
  ScheduleDocumentType,
} from '@structure';

import {
  FileProtectOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

const FileListSideWindowLazy = React.lazy(
  () => import('../Show/FileListSideWindow'),
);

const OrderSideWindowLazy = React.lazy(() => import('../Show/OrderSideWindow'));

export interface ICalendarEntryProps {}

export const DISABLED_SCHEDULE_ACTIONS = [
  ScheduleStatusesText.COMPLETED,
  ScheduleStatusesText.CANCELLED,
  ScheduleStatusesText.CLOSED,
];

const StyledExpanseContainer = styled.div`
  background-color: ${({ theme }) => theme.background.layout};
  padding: 10px;
  border-radius: 7px;
`;

const StyledExpanseTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

export default function OrderPage({}: ICalendarEntryProps): JSX.Element {
  const { scheduleId } = useParams();
  const { t } = useTranslation();
  const theme: any = useTheme();

  const [opLoading, setOpLoading] = useState<ScheduleDocumentType | null>();

  const { defaultCompany } = useStoredCompanies();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { scheduleDetails } = useCalendar();

  const { order: orderAccess } = useAcl((acl) => acl);

  const { visible, handleOnInit, handleCancel } = useModal();

  const {
    schedule,
    loading,
    stats,

    handleUpdateSchedule,
    handleUpdateScheduleComment,
    handleChangeScheduleStatus,
    handleUpdate,
    handlePrintScheduleDocument,
    handleFormingScheduleMessage,
    handleSendScheduleMessage,
    handleUpdateStats,
    handleCloseSchedule,
    refresh: handleRefreshSchedule,
  } = useStateSchedule({
    scheduleUuid: scheduleId!,
  });

  const { handleCreateSchedulePrepayment } = useStateSchedulePaymentList({
    loadOnInit: false,
    scheduleUuid: scheduleId!,
  });

  const expanseListParams = useStateExpenseList({
    scheduleUuid: schedule?.uuid!,
    handleUpdate,
    schedule,
  });

  const {
    handleUploadFiles,
    fileList,
    loading: fileListLoading,
    ...fileListParams
  } = useStoredFileList({
    scheduleUuid: scheduleId!,
    limit: 150,
    handleUpdateStats,
  });

  const isDisabledScheduleAction = DISABLED_SCHEDULE_ACTIONS.includes(
    schedule?.status_text,
  );

  const isHasStrictMode = useMemo(
    () => schedule?.schedule_has_strict_mode,
    [schedule?.schedule_has_strict_mode],
  );

  const isOrderCompleted = useMemo(
    () => schedule?.status_text,
    [schedule?.status_text],
  );

  const createSchedulePrepayment = useCallback(
    async (value: PaymentScheduleFormDTO) => {
      const prepayment = await handleCreateSchedulePrepayment(value);

      if (prepayment?.schedule) {
        const scheduleModel = ScheduleCalendarMapper.toScheduleCalendarModel(
          prepayment?.schedule,
        );

        handleUpdate(scheduleModel);
      }

      return prepayment;
    },
    [handleCreateSchedulePrepayment, handleUpdate],
  );

  const { allowedStatusesTo, loading: statusListLoading } = useStoredStatusList(
    {
      companyUuid: defaultCompanyUuid,
      limit: 100,
      allowedStatusesTo:
        schedule?.latestStatusModel?.customStatusModel?.allowed_statuses_to,
    },
  );

  const isWarningExpanseDescription =
    Number(expanseListParams?.aggregatedSum?.total_sum) > 0 ||
    expanseListParams?.expenseList?.size;

  const {
    handleCancel: scheduleEditHandleCancel,
    handleOnInit: scheduleDocumentEditOnInit,
    handleSuccess: scheduleDocumentEditSuccess,
    visible: scheduleEditDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateSchedule,
  });

  const documentOptions: MenuProps['items'] = [];
  const printOptions: MenuProps['items'] = [
    {
      key: ScheduleDocumentType.PreInvoice,
      label: <StyledTitle>{t('Pre invoice')}</StyledTitle>,
      icon:
        opLoading === ScheduleDocumentType.PreInvoice ? (
          <LoadingOutlined />
        ) : (
          <FileSyncOutlined />
        ),
      disabled: opLoading === ScheduleDocumentType.PreInvoice,
      onClick: async () => {
        setOpLoading(ScheduleDocumentType.PreInvoice);
        await handlePrintScheduleDocument(ScheduleDocumentType.PreInvoice);
        setOpLoading(null);
      },
    },
    {
      key: ScheduleDocumentType.ActCompletedWork,
      label: <StyledTitle>{t('Act of completed works')}</StyledTitle>,
      icon: <FileProtectOutlined />,
      disabled: opLoading === ScheduleDocumentType.ActCompletedWork,
      onClick: async () => {
        setOpLoading(ScheduleDocumentType.ActCompletedWork);
        await handlePrintScheduleDocument(
          ScheduleDocumentType.ActCompletedWork,
        );
        setOpLoading(null);
      },
    },
    ...(isOrderCompleted
      ? [
          {
            key: ScheduleDocumentType.Rewards,
            label: <StyledTitle>{t('Charge sheet')}</StyledTitle>,
            icon: <FileTextOutlined />,
            disabled: opLoading === ScheduleDocumentType.Rewards,
            onClick: async () => {
              setOpLoading(ScheduleDocumentType.Rewards);
              await handlePrintScheduleDocument(ScheduleDocumentType.Rewards);
              setOpLoading(null);
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <OrderPageTabPanel
        isDisabledScheduleAction={isDisabledScheduleAction}
        schedule={schedule}
        expanseListParams={expanseListParams}>
        {({ tabs }) => (
          <>
            {scheduleDetails(({ routes, navigateGoBack }) => {
              return (
                <OverviewLayout
                  isHeaderFlickering
                  underHeaderContent={
                    schedule?.uuid ? (
                      <OrderHeader
                        schedule={schedule}
                        loading={statusListLoading || loading}
                        handleChangeScheduleStatus={handleChangeScheduleStatus}
                        allowedStatusesTo={allowedStatusesTo}
                        handleCreateSchedulePrepayment={
                          createSchedulePrepayment
                        }
                        handleCloseSchedule={handleCloseSchedule}
                      />
                    ) : null
                  }
                  header={
                    <>
                      <OrderDetailsHeader
                        schedule={schedule}
                        handleUpdateScheduleComment={
                          handleUpdateScheduleComment
                        }
                        handleUploadFiles={handleUploadFiles}
                        handleOpenFileList={handleOnInit}
                        stats={stats}
                        isDisabledScheduleAction={isDisabledScheduleAction}
                      />

                      <StyledExpanseTitleContainer>
                        <StyledTitle bold>
                          {textToUpperCase(t('Services and materials'))}
                        </StyledTitle>
                        <StyledDescription
                          $color={
                            isWarningExpanseDescription
                              ? theme.colors.black
                              : theme.colors.warning
                          }>
                          {isWarningExpanseDescription
                            ? `${t('In the amount of')}: ${
                                defaultCompany?.currency_symbol || ''
                              } ${correctPrice(
                                expanseListParams?.aggregatedSum?.total_sum ||
                                  0,
                              )}`
                            : t('There are no services and materials')}
                        </StyledDescription>
                      </StyledExpanseTitleContainer>

                      <StyledExpanseContainer>
                        <ExpenseList
                          schedule={schedule}
                          isDisabledScheduleAction={isDisabledScheduleAction}
                          handleRefreshSchedule={handleRefreshSchedule}
                          {...expanseListParams}
                        />
                      </StyledExpanseContainer>
                    </>
                  }
                  headerTitleDescription={schedule?.schedule_title}
                  disabled={!orderAccess?.manage}
                  loading={statusListLoading || loading || fileListLoading}
                  data={null}
                  routes={routes}
                  headerTitle={t('Order')}
                  headerLeft={
                    <HeaderLeft absolute={false} onClick={navigateGoBack} />
                  }
                  headerRight={
                    <DocumentControlPanel
                      printItems={printOptions}
                      items={documentOptions}
                      onEditInit={scheduleDocumentEditOnInit}
                      isDraft={true}
                    />
                  }
                  outsideFooterContent={
                    <OrderBottomView
                      schedule={schedule}
                      handlePrintScheduleDocument={handlePrintScheduleDocument}
                      handleFormingScheduleMessage={
                        handleFormingScheduleMessage
                      }
                      handleSendScheduleMessage={handleSendScheduleMessage}
                    />
                  }
                  tabs={tabs}
                />
              );
            }, schedule)}
          </>
        )}
      </OrderPageTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <FileListSideWindowLazy
          {...fileListParams}
          fileList={fileList}
          loading={fileListLoading}
          handleUploadFiles={handleUploadFiles}
          schedule={schedule}
          onCloseDrawer={handleCancel}
          visible={visible}
          disabled={isDisabledScheduleAction}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <OrderSideWindowLazy
          editMode
          order={
            schedule &&
            ScheduleCalendarMapper.toScheduleCalendarFormDTO(schedule, true)
          }
          companyUuid={defaultCompanyUuid}
          loading={loading}
          onSuccess={scheduleDocumentEditSuccess}
          onCancel={scheduleEditHandleCancel}
          visible={scheduleEditDocumentVisible}
          isHasStrictMode={isHasStrictMode}
        />
      </Suspense>
    </>
  );
}

import {
  IRewardModelReturnType,
  IScheduleCalendarDTOProps,
  IProfileDTOProps,
  ScheduleCalendarDTO,
  ProfileDTO,
  ScheduleCalendarMapper,
  ProfileMapper,
  IEmployeeDTOProps,
  EmployeeModel,
  EmployeeMapper,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export interface IRewardStatsDTOProps {
  total: number;
  totalSumForPeriod: number;
  totalPayoutSumForPeriod: number;
  employee: IEmployeeDTOProps;
}

export class RewardStatsDTO {
  public total: number;
  public totalSumForPeriod: number;
  public totalPaid: number;
  public employee: EmployeeModel;

  constructor(props: IRewardStatsDTOProps) {
    this.total = correctPrice(props?.total || 0);
    this.totalSumForPeriod = correctPrice(props?.totalSumForPeriod || 0);
    this.totalPaid = correctPrice(props?.totalPayoutSumForPeriod || 0);
    this.employee = EmployeeMapper.toEmployeeModel(
      EmployeeMapper.toEmployeeDTO(props?.employee),
    );
  }
}
